import React from 'react';

export default function preloader() {
    return (
        <div className="preloader">
            {/* <div className="dots-container">
                <div className="dot red"></div>
                <div className="dot green"></div>
                <div className="dot yellow"></div>
            </div> */}
            <span className="sweb sweb-smile2win"></span>
        </div>
    )
}
