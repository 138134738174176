import React, { lazy, Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import Preloader from "./views/components/Preloader";
import reportWebVitals from './reportWebVitals';
import swDev from './swDev';
const App = lazy(() => import("./App"));


// ReactDOM.render(
//   // <React.StrictMode>
//   <Suspense fallback={<Preloader />}>
//     <App />
//   </Suspense>,
//   // </React.StrictMode>,
//   document.getElementById('root')
// );


const el = document.getElementById('root')
if (el === null) throw new Error('Root container missing in index.html')

const root = ReactDOMClient.createRoot(el)
root.render(
	<Suspense fallback={<Preloader />}>
		<App />
	</Suspense>
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();
